import styled from 'styled-components';
import arrowRight from '../../../images/arrow-right.svg';
import arrowBottom from '../../../images/arrow-bottom.svg';

export default styled.section`
  .feature-area {
    margin-top: 96px;
    @media screen and (max-width: 768px) {
      margin-top: 80px;
    }
    p {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 200%;
      text-align: center;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #fb692c;
    }
    .flow {
      div {
        font-family: Manufaktur-Bold, sans-serif;
        max-width: 204px;
        margin: 6px 16px;
        display: inline-block;
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 96%;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #ffffff;
        img {
          margin-top: -37px;
          margin-left: 32px;
          margin-right: 32px;
        }
      }
      .arrow-area {
        background-image: url(${arrowRight});
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
        background-position: center;
        margin: 5px;
        @media screen and (max-width: 768px) {
          margin: 0 auto;
          background-image: url(${arrowBottom});
        }
      }
      @media screen and (max-width: 768px) {
        div {
          margin: 14px auto;
          display: block;
        }
      }
    }
  }
`;
