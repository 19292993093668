import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import ThrottleFeatureStyles from './ThrottleFeatureStyles';

const Feature = () => (
  <ThrottleFeatureStyles>
    <Container>
      <Row className="feature-area">
        <Col sm={12} md={12} lg={12} className="text-center">
          <p>Featuring</p>
          <div className="flow">
            <div>INDUSTRY EXPERTS</div>
            <div className="arrow-area" />
            <div>in-person sessions</div>
            <div className="arrow-area" />
            <div>TACOS & BEER</div>
          </div>
        </Col>
      </Row>
    </Container>
  </ThrottleFeatureStyles>
);

export default Feature;
